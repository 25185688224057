.r-wrapper {
    display: flex;
    width: 100%;
    min-height: 100%;
    .r-header {
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        padding: 0 40px;
        background-color: white;
        justify-content: space-between;
        height: 60px;
        img {
            width: 35px;
            align-self: center;
        }  
        
        .r-links {
            align-self: center;
            font-family: PoppinsRegular;
            span {
                &:last-child {
                    margin-left: 10px;
                    cursor: pointer;
                }
                @media only screen and (max-width:420px) {
                    &:first-child{
                        display: block;
                    }
                    &:last-child {
                        margin-left: 0px;
                    }
                }
            }
        }
        @media only screen and (max-width:420px) {
            padding: 0 20px;
        }
    }
    .l-wrapper {
        flex-basis: 0%;
        flex-grow: 0.4;
        flex-shrink: 1;
        display: flex;
        padding: 40px 20px 20px 20px;
        background-color: white;
        align-items: stretch;
        .r-box {
            max-width: 480px;
            align-self: center;
            padding: 20px;
            margin-left: auto;
            margin-right: auto;
            .rs-wrapper {
                padding: 20px 0;
                @media only screen and (max-width:420px) {
           
                    padding: 10px 0;
                    
                }
            }
            .r-separator {
                font-family: PoppinsRegular;
                color: #c1c1c1;
                margin: 10px 0;
                text-align: center;
                position: relative;
                z-index: 1;
                span{
                    display: inline-block;
                    background-color: white;
                    padding: 0 10px;
                }
                &::after {
                    content: "";
                    position: absolute;
                    height: 1px;
                    top:calc(50% - 1px);
                    width: 100%;
                    background-color: #c1c1c1;
                    left:0;
                    z-index: -1;
                }
            }
            @media only screen and (max-width:420px) {
           
                padding: 0;
                h1 {
                    font-size: 20px;
                }
                .verticalPad {
                    padding-top: 0;
                }
                
            }
            @media only screen and (max-width:320px) {
           
                h1 {
                    font-size: 18px;
                }
            }
        }
        @media only screen and (max-width:920px) {
           
            flex-grow: 1;
        }
        @media only screen and (max-width:420px) {
           
            padding: 65px 20px 20px 20px;
        }
    }
    .i-wrapper {
        flex-grow:0.6;
        flex-basis: 0%;
        flex-shrink: 1;
        background-image: url(../../../assets/img/onboarding_background_register.png);
        background-size: cover;
        background-position: center;
        display: flex;
        .r-info {
            align-self: center;
            margin-left: auto;
            margin-right: auto;
            padding: 20px 60px;
            color:white;
            .r-logo{
                text-align: center;
                img {
                    width: 30vw;
                }
                margin-bottom: 3vw;
            }
            .r-title{
                color: white;
                text-align: center;
                margin-bottom: 40px;
                font-size: 16px;

                p {
                    font-family: PoppinsRegular;
                    font-size: 2.4em;
                    text-transform: uppercase;
                }
    
                span {
                    font-family: PoppinsSemiBold;
                    
                }

                @media only screen and (max-width:1166px) {
           
                    font-size: 14px;
                    
                }
            }
            .r-description {
                color: white;
                text-align: center;
                margin-bottom: 20px;
                font-size: 16px;

                p {
                    font-family: PoppinsRegular;
                    font-size: 1.7em;
                    text-transform: uppercase;
                }
    
                span {
                    font-family: PoppinsSemiBold;
                    
                }
                @media only screen and (max-width:1166px) {
           
                    font-size: 14px;
                    
                }
            }
        }

        @media only screen and (max-width:920px) {
           
            display: none;
            
        }
    }
}

.as-H1 {
    font-family: PoppinsBold;
}

.rs-btn {
    display: inline-flex;
    height: 50px;
    border-radius: 2px;
    cursor: pointer;
    .logo {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50px;
        background-color: white;
        padding: 5px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .content {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        font-family: PoppinsSemiBold;
        color: white;
        align-self: center;
        padding: 5px 5px 5px 15px;
       
    }
    &.lightblue {
        background-color: #5183ee;
        border: solid 2px #5283ee;
    }
    &.blue {
        background-color: #143482;
        border: solid 2px #143482;
    }
    &.fluid {
        width: 100%;
    }

    @media only screen and (max-width:320px) {
           
        height: 38px;
        
    }
}



.tos-message{
    font-size: 12px !important;
    line-height: 22px !important;
    color: var(--y-grey5) !important;
}