/********************************PREDEFINED INPUT (TEXT, PASSWORD) CLASSES********************************/

.custom-input input, .custom-select > div{
    border: 0 !important;
    border-bottom: solid 1px var(--grey5) !important;
    border-radius: 0 !important;
    padding-left: 0!important;
}

.custom-input label, .custom-select label{
    color: var(--y-green1) !important;
}

.custom-checkbox label {
    color: var(--grey4) !important;
    font-size: 12px !important;
    padding-left: 30px!important;
    &:before {
        border: 1px solid var(--grey4) !important;
       
    }
}
// .ui.radio.checkbox input:focus:checked~.box:after, .ui.radio.checkbox input:focus:checked~label:after

.custom-radio label{
   
    &::before {
        background-color: white!important;
        border-radius: 0!important;
    }
    
}

.custom-radio {
    input {
        &:focus{
            &:checked{
                &~label:after{
                    background-color: var(--y-green1)!important;
                }
            }
        }
        &:checked{
            &~label:after{
                background-color: var(--y-green1)!important;
            }
        }
        
    }
}

.y-select > div {
    border: solid 1px var(--y-grey1) !important;
    border-radius: 3px !important;
    background: transparent!important;
   
}
.y-select > div > div {
    color: var(--y-grey1)!important;
}

.y-select label {
    font-family: PoppinsMedium;
    font-size: 14px!important;
    line-height: 21px!important;
    text-align: left;
    font-weight: 500!important;
    color: var(--y-grey5)!important;
}

.y-input  {

    input {
        border: solid 1px var(--y-grey1) !important;
        border-radius: 3px !important;
        background: transparent!important;
        padding: 22px 25px !important;
        color: var(--y-grey1) !important;;
        &::placeholder{
            color: var(--y-grey1) !important;
        }

    }

    &.small {
        input{
            padding: 8px 10px !important;
        }
        
    }
   
}

.y-input {

    label {
        font-family: PoppinsMedium;
        color: white!important;
        font-weight: 500!important;
        font-size: 24px!important;
        line-height: 36px!important;
        @media only screen and (max-width: 420px) {
            font-size: 20px!important;;
        }
    }

    &.small {

        label {
            font-size: 14px!important;
            line-height: 21px!important;
            text-align: left;
            color: var(--y-grey5)!important;
        }
    }
    
}


.y-checkbox  {

    label {

        color: white !important;
        font-size: 14px !important;
        padding-left: 44px!important;
        font-family: PoppinsRegular!important;
        line-height: 24px!important;
        &:before {
            border: 1px solid var(--y-grey2) !important;
            width: 24px!important;
            height: 24px!important;
            background: transparent!important;
        }
        &:after {
        
            width: 24px!important;
            height: 24px!important;
            line-height: 24px!important;
            color: var(--y-green1)!important;
        }

        @media only screen and (max-width: 420px) {
            font-size: 12px!important;
        }

    }

    &.small {
        label {
            font-size: 12px !important;
            line-height: 22px!important;
            padding-left: 44px!important;
            color: var(--y-grey5)!important;
        }
        
    }
}

.y-checkbox input {
    width: 24px!important;
    height: 24px!important;
}