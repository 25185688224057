.wrap {

    background-color: var(--y-grey3);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrap-grid {

        margin: 0 auto;
        max-width: 1400px;
        padding: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        position: relative;
        top: 10%;
        width: 100%;
        font-size: 16px;
        .left-content {
            font-family: PoppinsRegular;
            color:white;
            .back-btn {
                display: inline-block;
                font-family: PoppinsSemiBold;
                padding: 10px 32px;
                color: white;
                background-color: var(--y-grey9);
                text-transform: uppercase;
                cursor: pointer;
                border-radius: 40px;
                margin: 20px 0;

            }
            .first-title {
                font-size: 6em;
            }

            .first-description{
                font-size: 1.563em;
                text-align: left;
            }

            .second-title {
                margin-top: 20px;
                font-size: 2.250em;
            }
            .wrap-list {
                margin-top: 20px;
                .list-item {
                    font-size: 1.500em;
                    position: relative;
                    margin-bottom: 20px;
                    
                    p {
                        color: var(--y-green1);
                        display: inline;
                        cursor: pointer;
                    }
                    i {
                        color: whitesmoke;
                        margin-left: 20px;
                        font-family: PoppinsBold;
                        
                    }

                    &:before {
                        content:"\A";
                        display:inline-block;
                        width: 10px;
                        height: 10px;
                        margin-right:10px;
                        background-color: var(--y-grey14);
                        border-radius: 50%;

                    }
                    
                }
            }

            @media only screen and (max-width: 890px) {
                order: 2;
            }
        }

        .right-content {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                height: auto;
            }
        }

        @media only screen and (max-width: 1280px) {
            font-size:14px;
        }
        @media only screen and (max-width: 1024px) {
            font-size:12px;
        }

        @media only screen and (max-width: 890px) {
            grid-template-columns: 1fr;
        }
        @media only screen and (max-width: 420px) {
            font-size:10px;
        }
    }
}