.custom-link {

    text-decoration: none;
    font-size: 13px;
    font-family: RobotoBold;
    margin: 0 10px;
    padding: 7px 15px;
    border-radius: 3px;
    color: var(--grey6);

    &:visited{
        color: var(--grey6);
    }

    &:hover{
        color: var(--grey6);
    }

    &.bloqued {
        background-color: var(--grey5);
        i {
            font-size: 16px;
        }
    }

    &.active {
        background-color: var(--blue4);
        i {
            font-size: 16px;
        }
    }

    &.small {
        font-size: 13px!important;
        i {
            font-size: 14px;
        }
    }
}