
.wrapper {

    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 375px) {
        align-items: flex-start;
    }
}

/******************************* LOGIN *******************************/

.login-background {
	background-image: url('../img/landing/4.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.login-box {
	width: 450px;
	min-height: 654px;
	background: white;
	box-sizing: border-box;
	-webkit-box-shadow: 0px 0px 28px -15px rgba(0,0,0,1);
	-moz-box-shadow: 0px 0px 28px -15px rgba(0,0,0,1);
    box-shadow: 0px 0px 28px -15px rgba(0,0,0,1);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    .login-body {
        padding: 15px 25px;
        .login-icon {
            width: 104px;
            height: 104px;
            background-image: url('../img/yogabot_icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            margin: 30px auto;
        }
        .separator {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            color: var(--grey1)!important;
            .text{
                cursor: pointer;
                &.text-m {
                    font-size: 12px;
                    font-family: RobotoBold!important;
                }
                &.text-l {
                    font-size: 14px;
                    font-family: RobotoBold!important;
                }
            }
            
        }
        
    }

    &.opacity {
        background-color: rgba(255, 255, 255, 0.65);
        .custom-input input {
            background: transparent!important;
        }
        .custom-checkbox label {
            color: var(--grey1)!important;
            font-family: RobotoBold;
            padding-left: 20px!important;
        }
        
        @media only screen and (max-width: 475px) {
            background-color: white;
        }
    }

    .arrow-back {
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
    }

    @media only screen and (max-width: 475px) {
        width: 100%;
        border-radius: 0px;
        min-height: 100vh;
      }

}

/*LOGIN PAGE*/

.wrapper-login {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--y-grey3);
    position: relative;
    z-index: 1;
    &::after {
        content: "";
        background: url('../img/meteoros.png');
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1; 
        background-repeat: no-repeat;
        background-position: top right;
        @media only screen and (max-width:420px) {
            opacity: 0.1;
            
        }  
    }
    .box {
        width: 660px;
        min-height: 654px;
        padding: 20px;
        box-sizing: border-box;
        .logo, .correct-logo, .logo-mobile, .correct-logo-mobile{
            text-align: center;
            margin: 0 auto;
            img {
                max-width: 100%;
            }
        }

        .logo, .correct-logo {
            display: none;
            padding-top: 30px;
            @media only screen and (min-width: 420px) {
                display: block;
            }
        }

        .logo-mobile,.correct-logo-mobile{
            display: none;
            // padding-top: 40px;
            @media only screen and (max-width: 419px) {
                display: block;
            }
        }

        .correct-logo{
            padding-top: 30px;
            @media only screen and (max-width: 420px) {
                width: 250px;
            }
        }

        .rrss-title{
            text-align: center;
            color:white;
            font-family: PoppinsRegular;
            font-size: 20px;
            @media only screen and (max-width: 419px) {
                font-size: 15px;
            }
        }

        .rrss-subtitle{
            text-align: center;
            color:var(--y-green1);
            font-family: PoppinsMedium;
            font-size: 24px;
            line-height: 36px;
            margin: 20px auto;
        }
        .rrss-icons {
            display: grid;
            grid-template-columns: 81px 20px 81px;
            padding: 10px;
            width: 203px;
            background-color: var(--y-grey14);
            border-radius: 3px;
            margin: 20px auto;
            .item {
                height: 60px;
                width: 100%;
                padding: 5px;
                text-align: center;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                border-radius: 3px;
                cursor: pointer;
                img {
                    max-width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                &.fb{
                    background-color: var(--y-blue1);
                }
                &.gl{
                    background-color: var(--y-red1);
                }
            }

            .middle-bar {
                width: 3px;
                height: 36px;
                background-color: var(--y-grey9);
                margin: 0 auto;
                align-self: center;
            }
            
            @media only screen and (max-width: 419px) {
                grid-template-columns: 60px 20px 60px;
                width: 160px;
                .item {
                    height: 50px;
                }
            }
            @media only screen and (max-width:360px) {

                grid-template-columns: 50px 20px 50px;
                width: 140px;
                .item {
                    height: 40px;
                }
             }
        }

        .separator-bar {
            margin-top: 40px;
            display: grid;
            grid-template-columns: 1fr 94px 1fr;
            .bar {
                width: 100%;
                height: 1px;
                background-color: var(--y-grey14);
                align-self: center;
            }
            .circle {
                color: var(--y-green1);
                font-family: PoppinsMedium;
                font-size: 24px;
                text-align: center;
            }
            
            @media only screen and (max-width: 419px) {
                margin-top: 20px;
            }
        }

        .correct-message-title {
            padding-top: 72px;
            color:white;
            font-family: PoppinsBold;
            font-size: 20px;
            text-align: center;
            @media only screen and (max-width: 420px) {
                 font-size: 18px;
            }

        }

        .correct-message-subtitle {
            
            color:white;
            font-family: PoppinsRegular;
            font-size: 20px;
            text-align: center;
            margin-top: 10px;
            @media only screen and (max-width: 420px) {
                 font-size: 18px;
            }
            
        }
        
        .title {
            font-size: 40px;
            font-family: PoppinsMedium;
            color: var(--y-green1);
            text-align: center;
            padding-top: 72px;
            line-height: 45px;
            span {
                font-size: 30px;
                color: white;
                font-family: PoppinsRegular;
                @media only screen and (max-width: 420px) {
                    font-size: 24px;
               }
            }
            
            @media only screen and (max-width: 420px) {
                font-size: 32px;
                padding-top: 20px;
                line-height: 29px;
           }

        }
        
        .text {
            padding-top: 72px;
            color: white;
            font-family: PoppinsRegular;
            font-size: 18px;
            text-align: left;
            padding-bottom: 32px;
            @media only screen and (max-width: 420px) {
                font-size: 16px;
                padding-top: 32px;
           }
        }

        .forgot-password {
            position: relative;
            .forgot-password-text{
                position: absolute;
                top: 22px;
                right: 0;
                color: white;
                font-size: 14px;
                font-family: PoppinsRegular;
                cursor: pointer;
                @media only screen and (max-width: 420px) {
                    font-size: 12px;
               }
            }
        }

        .y-input {
            label{
                font-size: 16px !important;
                @media only screen and (max-width: 420px) {
                    font-size: 14px !important;
               }
            }
            input {
                @media only screen and (max-width: 420px) {
                    padding: 8px 10px !important;
               }
            }
            
        }

        .descriptions {
            display: flex;
            color: white;
            font-size: 14px;
            line-height: 21px;
            font-family: PoppinsRegular;
            font-weight: normal;
            margin-top: 15px;
            &.first {
                justify-content: space-between;
                
            }
            &.second {
                margin-top: 0;
                span{
                    margin-top: 8px;
                }
                // button{
                //     padding: 5px 43px;
                //     margin: 0!important;
                //     @media only screen and (max-width: 420px) {
                //         width: 100%;
                //         font-size: 20px;
                //         padding: 3px 43px;
                //    }
                // }
            }

            span {
                cursor: pointer
            }
            @media only screen and (max-width: 420px) {
                font-size: 12px;
           }
        }

        @media only screen and (max-width: 660px) {
            width: 100%;
            padding: 32px;
        }

         @media only screen and (max-width: 420px) {
                min-height: auto;
           }

        @media only screen and (max-width:360px) {
            padding: 20px;
            .verticalPad {
                padding-top: 0;
            }
            .verticalPad-long {
                padding: 30px 0;
            }
       }
    }
}


/******************************* LANDING *******************************/

.main-wrapper {
    min-height: 100%;
    background: black;
    .full-page {
        position: relative;
        margin-top: -91px;//Alto del navbar
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 60px;
        background-image: linear-gradient(357.87deg, rgba(0, 0, 0, 0.73) 24.45%, rgba(0, 0, 0, 0.353594) 95.85%), url('../img/landing/r2_large.jpg');
        background-size: cover;
        background-position: center center;
        min-height: 100vh;
        max-height: 1080px;
        z-index: 0;
        .content {
            position: relative;
            padding: 15px;
            .title, .subtitle, .description{
                font-family: RobotoBold;
                color: white;
                text-align: center;
            }
            .title {
                font-size:45px;
                font-weight: bold;

                @media only screen and (max-width: 1024px) {
                    font-size:40px;
                }
                @media only screen and (max-width: 720px) {
                    font-size:35px;
                }
                @media only screen and (max-width: 520px) {
                    font-size:26px;
                }

            }
            .subtitle {
                font-size:35px;
                margin: 32px 0;
                font-family: RobotoLight;
                letter-spacing: 1.2px;
                b {
                    font-family: RobotoBold;
                }

                @media only screen and (max-width: 1024px) {
                    font-size:20px;
                }
                @media only screen and (max-width: 720px) {
                    font-size:18px;
                }
                @media only screen and (max-width: 520px) {
                    font-size:16px;
                }
            }
            .description {
                font-size:18px;
                margin-top: 20px;
                font-family: RobotoLight;
                b {
                    font-family: RobotoBold;
                }
                @media only screen and (max-width: 720px) {
                    font-size:14px;
                }
            }
            .landing-form {
                button {
                    i {
                        line-height: 16px;
                    }
                    
                }
                .test-now-wrap {
                    button {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .box-btn {
                max-width: 761px;
                margin: 0 auto;
                .box-desktop {
                    
                    input {
                        height: 55px;
                        @media only screen and (max-width: 950px) {
                            border-radius: .28571429rem!important;
                        }
                        
                    }
                    button {
                        @media only screen and (max-width: 950px) {
                            display: none;

                        }
                    }
                }

                @media only screen and (max-width: 820px) {
                    min-width: 100%;
                    margin: 0 auto;
                  }
            }

            @media only screen and (max-width: 720px) {
                padding:30px;
            }
        }

        
    }

    /******************************* LANDING SECTIONS*******************************/

    .section {
        background: black;
        // border-bottom: solid 15px var(--grey1);
        margin: 0 auto;
        max-width: 1440px;
        .section-content{
            max-width: 1280px;
            margin: 0 auto;
            padding: 70px 40px;
            display: flex;
            .text {
                padding: 20px;
            }
            .image{
                padding: 32px;
            }
            .text {
                flex: 48%;
                @media only screen and (max-width: 920px) {
                    text-align: center;
                  }
            }
            .image{
                flex: 52%;
                @media only screen and (max-width: 920px) {
                    max-width: 600px;
                    margin: 0 auto;
                  }
            }
            .title, .subtitle {
                color: white;
            }
            .title {
                font-family: RobotoBold;
                font-size: 48px;
                @media only screen and (max-width: 720px) {
                    font-size: 40px;
                  }
                @media only screen and (max-width: 520px) {
                    font-size:32px;
                }

                &.faq {
                    margin: 0 auto;
                }
            }
            .subtitle {
                font-family: RobotoRegular;
                font-size: 34px;
                @media only screen and (max-width: 720px) {
                    font-size: 24px;
                  }
            }
            .image {

                img {
                    max-width: 100%;
                    height: auto;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }
            }
            &.faq {
                flex-direction: column;
                @media only screen and (max-width: 520px) {
                    padding: 70px 0px;
                  }
            }

            &.row-reverse {
                flex-direction: row-reverse;
                @media only screen and (max-width: 920px) {
                    flex-direction: column;
                  }
            }
            @media only screen and (max-width: 920px) {
                flex-direction: column;
              }
        }

        
    }

    &.alternative {
        background:white;
        .full-page {
            background-image: linear-gradient(357.87deg, rgba(0, 0, 0, 0.73) 24.45%, rgba(0, 0, 0, 0.353594) 95.85%), url('../img/landing/r1_large.jpg');
            // border-bottom: solid 15px var(--grey6);
        }
        .section { 
            background:white;
            // border-bottom: solid 15px var(--grey6);
            .section-content{
                .title, .subtitle {
                    color: var(--grey1);
                }
            }
        }
    }
}

.i-back {
    display: inline-flex;
    margin-bottom: 10px;
    cursor: pointer;
    i {
        margin-right: 5px;
        height: auto;
        &:before {
            font-size: 25px;
            color:#242a42;
            opacity: 0.5;
        }
    }
    span {
        font-family: PoppinsRegular;
        font-size: 14px;
        color: var(--y-grey3);
    }
    @media only screen and (max-width: 920px) {
        display: none;
      }
}