.heading-1,.heading-2, .heading-3, .paragraph, .caption, .labels, .paragraph-to-caption, .stats-text {

    font-family: var(--font-primary);
    color: var(--brand-primary);
    &.regular {
        font-family: var(--font-secondary);
    }
    &.white {
        color:var(--brand-white);
    }
    &.text-link {
        color: var(--blue-link);
    }
}

.heading-1 {
    font-size: 28px;
    line-height: 1;
    @media only screen and (max-width:420px) {
        font-size: 26px;
    }
}

.heading-2 {
    font-size: 24px;
    @media only screen and (max-width:420px) {
        font-size: 20px;
    }
}

.heading-3 {
    font-size: 18px;
    @media only screen and (max-width:420px) {
        font-size: 16px;
    }
}

.paragraph {
    font-size: 16px;
    @media only screen and (max-width:420px) {
        font-size: 14px;
    }
}

.paragraph-to-caption {
    font-size: 16px;
    @media only screen and (max-width:720px) {
        font-size: 15px;
    }
    @media only screen and (max-width:420px) {
        font-size: 12px;
    }
}

.labels {
    font-size: 14px;
}

.caption {
    font-size: 12px;
}

.stats-text {
    font-size: 48px;
    line-height: 1;
    @media only screen and (max-width:420px) {
        font-size: 26px;
    }
}
