.brand-primary {
    color: var(--brand-primary, #30425a) !important;
}

.brand-primary-light {
    color: var(--brand-primary-light, #98a3af) !important;
}

.brand-primary-extra-light {
    color: var(--brand-primary-extra-light, #ccd1d7) !important;
}

.brand-secondary {
    color: var(--brand-secondary, #1fd5b9) !important;
}

.brand-secondary-dark {
    color: var(--brand-secondary-dark, #1bb89f) !important;
}

.brand-black {
    color: var(--brand-black, #000000) !important;
}

.brand-white {
    color: var(--brand-white, #ffffff) !important;
}

.dark-grey {
    color: var(--dark-grey, #3d3d3d) !important;
}

.dark-grey-2 {
    color: var(--dark-grey-2, #686c71) !important;
}

.dark-grey-3 {
    color: var(--dark-grey-3, #84898f) !important;
}

.dark-grey-4 {
    color: var(--dark-grey-4, #eaecef) !important;
}

.dark-grey-5 {
    color: var(--dark-grey-5, #CDCDCD) !important;
}

.light-grey-1 {
    color: var(--light-grey-1, #b2bac4) !important;
}

.dark-grey-2 {
    color: var(--dark-grey-2, #f8f8f8) !important;
}

.brand-error {
    color: var(--brand-error, #e20d0d) !important;
}

.brand-positive {
    color: var(--brand-positive, #2dcc70) !important;
}

.brand-alarm {
    color: var(--brand-alarm, #ffcf00) !important;
}

.blue-link {
    color: var(--blue-link, #3598d4) !important;
}

// Query status colours
.pending-accept {
    color: var(--pending-accept, #F2994A) !important;
}

.pending-answer {
    color: var(--pending-answer, #FFCF00) !important;
}

.pending-revision {
    color: var(--pending-revision, #135A4F) !important;
}

.pending-supervision {
    color: var(--pending-supervision, #1CA18C) !important;
}

.pending-nexoyoga {
    color: var(--pending-nexoyoga, #1FD5B9) !important;
}

.ended {
    color: var(--ended, #828282) !important;
}

.brand-white {
    color: var(--brand-white, #ffffff) !important;
}