.roboto-bold {
    font-family: RobotoBold!important;
}

.poppins-semibold {
    font-family: PoppinsSemiBold!important;
}

.poppins-bold {
    font-family: PoppinsBold!important;
}
.pink-color {
    color: var(--y-green1)!important;
}
.grey3 {
    color: var(--grey3);
}
.grey4 {
    color: var(--grey4);
}
.yellow{
    color: var(--yellow);
}

.verticalPad {
    padding-top: 15px;
    padding-bottom: 15px;
}

.topPad{
    padding-top: 15px;
}

.footer-separator {
    padding-top: 50px;
}

.verticalPad-small {
    padding-top: 8px;
    padding-bottom: 8px;
}
.verticalPadBottom {
    padding-bottom: 15px;
}
.verticalPad-long {
    padding: 50px 0;
}
.f-left{
    float: left;
}
.f-right {
    float: right;
}
.upper {
    text-transform: uppercase!important;
}

.resetMargin{
    margin: 0!important;
}

.t-center {
    text-align: center!important;;
}

.t-left {
    text-align: left!important;;
}

.justify {
    text-align: justify!important;
}

.disabled-nav{
    opacity: .3;
}

.flex-center-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mirror{
    transform: scaleX(-1);
}

.blurred {
    filter: blur(5px) grayscale(.7);
}

.hide-modal{
    
    &.visible{
        &.transition {
            display: none!important;
        }
    }
}

.floating {

    position: absolute;
    top: 20px;
    right: 20px;
    // font-size: 15px!important;;
    z-index: 10;
    @media only screen and (max-width: 420px) {
        top: 10px;
        right: 10px;
        // font-size: 13px!important;;
    }
}

.hide-video {
    display: none!important;
}

.video-transition {
    position: absolute;
    top: 0;
    left: 0;
}

.inline-block {
    
    display: inline-block;
    
}

.remove-disabled-opacity * {
    opacity: 1!important;
}

.cwhite {
    color: white;
}
.cbrand {
    color: var(--y-green1)!important;
}

.clear {
    clear: both;
}

.f-center {
    button {
        display: inline-flex!important;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    
}
.floating-l {
    float: left;
    width: 100%;
    z-index: 2;
    min-height: 1px;
    position: relative;
    
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .t-shadow {
    text-shadow: 0.08em 0.08em 0.08em rgba(0,0,0,0.4);
  }

  .icon-white {
      &::before {
          color: white!important;
      }
  }

  .cursive {
      font-style: italic;
  }

  .super-vertical {
      .radio {
        vertical-align: super;

      }
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .c-pointer {
      cursor: pointer;
  }

  .hide-input {

        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        bottom: 0;
        z-index: 1;
        left: 0;

  }

  .nomargin {
      margin: 0;
  }

  .asDisabled {
      opacity: 0.3;
  }

  .mTop30 {
      margin-top: 30px;
  }

  .dragging-helper-class {
    background-color: white;
    display: flex;
    padding: 10px;
    vertical-align: middle;
    justify-content: space-between;
    align-items: center;
  }

  .label-warning {
    background-color: var(--pending-answer, #FFCF00) !important;
    color:#fff!important;
  }

  .wordBreak {
    word-break: break-word;
  }